:root {
  --primary: #057dcd;
  --secondary: #1e3d58;
  --light: #f8f2f0;
  --dark: #060315;
}

.fw-medium {
  font-weight: 600 !important;
}

.back-to-top {
  z-index: 99;
  display: none;
  position: fixed;
  bottom: 45px;
  right: 45px;
}

#spinner {
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
}

#spinner.show {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s ease-out, visibility linear;
}

.btn {
  font-weight: 600;
  transition: all .5s;
}

.btn.btn-primary, .btn.btn-secondary {
  color: #fff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square, .btn-sm-square, .btn-lg-square {
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: normal;
  display: flex;
}

.navbar .dropdown-toggle:after {
  content: "";
  vertical-align: middle;
  border: none;
  margin-left: 8px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-transform: uppercase;
  outline: none;
  margin-right: 30px;
  padding: 25px 0;
  font-size: 15px;
  position: relative;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eee;
  }
}

.navbar-light .navbar-brand, .navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  transition: all .5s;
  top: -100px;
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link:before {
    content: "";
    width: 0;
    height: 5px;
    background: #fff;
    transition: all .5s;
    position: absolute;
    top: -6px;
    left: 50%;
  }

  .navbar-light .navbar-nav .nav-link:hover:before, .navbar-light .navbar-nav .nav-link.active:before {
    width: 100%;
    left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact:before {
    display: none;
  }

  .navbar .nav-item .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    border: none;
    margin-top: 0;
    transition: all .5s;
    display: block;
    top: 150%;
  }

  .navbar .nav-item:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    transition: all .5s;
    top: 100%;
  }
}

@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
    min-height: 500px;
    position: relative;
  }

  .header-carousel .owl-carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  .header-carousel .owl-carousel-item h5, .header-carousel .owl-carousel-item p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
    font-size: 30px;
    font-weight: 600;
  }
}

.header-carousel .owl-nav {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
}

.header-carousel .owl-nav .owl-prev, .header-carousel .owl-nav .owl-next {
  width: 45px;
  height: 45px;
  color: #fff;
  background: none;
  border: 1px solid #fff;
  border-radius: 45px;
  justify-content: center;
  align-items: center;
  margin: 7px 0;
  font-size: 22px;
  transition: all .5s;
  display: flex;
}

.header-carousel .owl-nav .owl-prev:hover, .header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.page-header {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(6, 3, 21, .5), rgba(6, 3, 21, .5)), url("carousel-1.e667e771.jpg");
  background-position: 0 0, center;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: var(--light);
}

@media (min-width: 992px) {
  .container.about {
    max-width: 100% !important;
  }

  .about-text {
    padding-right: calc(50% - 480px + .75rem);
  }
}

@media (min-width: 1200px) {
  .about-text {
    padding-right: calc(50% - 570px + .75rem);
  }
}

@media (min-width: 1400px) {
  .about-text {
    padding-right: calc(50% - 660px + .75rem);
  }
}

@media (min-width: 992px) {
  .container.feature {
    max-width: 100% !important;
  }

  .feature-text {
    padding-left: calc(50% - 480px + .75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text {
    padding-left: calc(50% - 570px + .75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text {
    padding-left: calc(50% - 660px + .75rem);
  }
}

.service-item, .price-item, .team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item img, .team-item img {
  transition: all .5s;
}

.service-item:hover img, .team-item:hover img {
  transform: scale(1.1);
}

.service-item a.btn-slide, .price-item a.btn-slide, .team-item div.btn-slide {
  font-size: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.service-item a.btn-slide i, .service-item a.btn-slide span, .price-item a.btn-slide i, .price-item a.btn-slide span, .team-item div.btn-slide i, .team-item div.btn-slide span {
  height: 40px;
  color: #fff;
  background: var(--primary);
  z-index: 2;
  border-radius: 0 35px 35px 0;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
  transition: all .5s;
  display: inline-flex;
  position: relative;
}

.team-item div.btn-slide span a i {
  padding: 0 10px;
}

.team-item div.btn-slide span a:hover i {
  background: var(--secondary);
}

.service-item a.btn-slide span, .price-item a.btn-slide span, .team-item div.btn-slide span {
  z-index: 1;
  padding-left: 0;
  left: -100%;
}

.service-item:hover a.btn-slide i, .price-item:hover a.btn-slide i, .team-item:hover div.btn-slide i {
  border-radius: 0;
}

.service-item:hover a.btn-slide span, .price-item:hover a.btn-slide span, .team-item:hover div.btn-slide span {
  left: 0;
}

.service-item a.btn-slide:hover i, .service-item a.btn-slide:hover span, .price-item a.btn-slide:hover i, .price-item a.btn-slide:hover span {
  background: var(--secondary);
}

.testimonial-carousel .owl-item .testimonial-item {
  transition: all .5s;
  position: relative;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  animation: pulse 1s ease-out .5s;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.testimonial-carousel .owl-dots {
  justify-content: center;
  align-items: center;
  display: flex;
}

.testimonial-carousel .owl-dot {
  width: 15px;
  height: 15px;
  background: var(--primary);
  border: 5px solid var(--light);
  border-radius: 15px;
  margin: 0 5px;
  transition: all .5s;
  display: inline-block;
  position: relative;
}

.testimonial-carousel .owl-dot.active {
  background: var(--light);
  border-color: var(--primary);
}

@media (min-width: 992px) {
  .container.contact-page {
    max-width: 100% !important;
  }

  .contact-page .contact-form {
    padding-left: calc(50% - 480px + .75rem);
  }
}

@media (min-width: 1200px) {
  .contact-page .contact-form {
    padding-left: calc(50% - 570px + .75rem);
  }
}

@media (min-width: 1400px) {
  .contact-page .contact-form {
    padding-left: calc(50% - 660px + .75rem);
  }
}

.footer {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(6, 3, 21, .5), rgba(6, 3, 21, .5)), url("map.393c0f12.png");
  background-position: 0 0, center;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.footer .btn.btn-social {
  width: 35px;
  height: 35px;
  color: var(--light);
  border: 1px solid #fff;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  transition: all .3s;
  display: flex;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 5px;
  padding: 0;
  font-size: 15px;
  font-weight: normal;
  transition: all .3s;
  display: block;
}

.footer .btn.btn-link:before {
  content: "";
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: relative;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  border-top: 1px solid rgba(255, 255, 255, .1);
  padding: 25px 0;
  font-size: 15px;
}

.footer .copyright a {
  color: var(--light);
}

.hidden {
  display: none;
}

/*# sourceMappingURL=style.css.map */
